$(document).ready(function() {
  var closing_date_ms = localStorage.getItem('delivery_banner_closing_date_ms');
  if (closing_date_ms && parseInt(closing_date_ms) + 1000 * 60 * 60 * 24 * 2 > (+ new Date())) {
    $('#delivery_banner').addClass('invisible');
  }else{
    $('#delivery_banner').removeClass('invisible');
  }
  var banners = document.querySelectorAll('[id^="delivery_banner_"]');
  var newsIdList = JSON.parse(localStorage.getItem('newsIdList')) || [];
  show_more(banners, newsIdList);
    
  banners.forEach(function(banner, index) {
    var bannerId = parseInt(banner.id.split('_').pop());
    if (newsIdList.includes(bannerId)) {
      banner.classList.add('invisible'); 
    } else if(index > 3) {
      banner.classList.add('invisible'); 
    } else {
      $('.news-container').removeClass('invisible');
      banner.classList.remove('invisible');
    }
  });
  $('.news').removeClass('invisible');
  
});

$(document).on('click','#delivery_banner .close',function () {
  localStorage.setItem('delivery_banner_closing_date_ms', + new Date());
  $('#delivery_banner').addClass('invisible');
});

$(document).on('click', '.close', function () {
    var bannerId = $(this).data('id');
    $('#delivery_banner_' + bannerId).addClass('invisible');
    var newsIdList = JSON.parse(localStorage.getItem('newsIdList')) || [];
    newsIdList.push(bannerId);
    localStorage.setItem('newsIdList', JSON.stringify(newsIdList));
    var banners = document.querySelectorAll('[id^="delivery_banner_"]');
    for (var index = 0; index < banners.length; ++index) {
      var bannerIdNews = parseInt(banners[index].id.split('_').pop());
      if (!newsIdList.includes(bannerIdNews) && index >= 4 &&  banners[index].classList.contains('invisible')) {
        banners[index].classList.remove('invisible');
        show_more(banners, newsIdList);
        break; 
      }
    }      
});

$(document).on('click', '#show-more', function () {
  const hiddenNews = document.querySelectorAll('.delivery_banner.invisible');
  var newsIdList = JSON.parse(localStorage.getItem('newsIdList')) || [];
  hiddenNews.forEach(function(news) {
    
    var bannerId = parseInt(news.id.split('_').pop());
      if (!newsIdList.includes(bannerId)) {
        news.classList.remove('invisible');
      } 
  });
  this.style.display = 'none'; 
});
function show_more(banners, newsIdList) {

  if (banners.length - newsIdList.length > 4){
    var show_all = document.getElementById('show-more');
    show_all.classList.remove('invisible');
  }else{
    var show_all = document.getElementById('show-more');
    show_all.classList.add('invisible');
  }
}
